/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import Constants from '../constants';
import { saveCookie } from '../utils';

const {
  GRID_VIEW_MODE,
  DEFAULT_SELECTED_LOB,
  SORT_DEFAULT_CRITERIA,
  RELEASE_CONTENT_FOR,
  STATUS_FILTER_DEFAULT,
  HIDDEN
} = Constants;

export default types
  .model('Filters', {
    searchTerm: '',
    selectedLoB: DEFAULT_SELECTED_LOB,
    sortCriteria: SORT_DEFAULT_CRITERIA,
    projectPublishStatus: STATUS_FILTER_DEFAULT,
    projectPublishPlatform: STATUS_FILTER_DEFAULT,
    mode: GRID_VIEW_MODE,
    showNewReleaseContent: true,
    previousViewMode: GRID_VIEW_MODE,
    productCategory: STATUS_FILTER_DEFAULT,
    cypressplus: false,
    isTccProject: false,
    isH5PContentEnabled: false,
    enableTeacherAssistant: false,
    isSbrEnabled: false,
    isOthersEnabled: false
  })
  .views(self => ({
    getQueryParams() {
      return {
        searchTerm: self.searchTerm.trim(),
        lineOfBusiness: self.selectedLoB,
        sort: self.sortCriteria,
        viewmode: self.mode,
        projectPublishStatus: self.projectPublishStatus,
        projectPublishPlatform: self.projectPublishPlatform,
        category: self.productCategory,
        cypressplus: self.cypressplus,
        isTccProject: self.isTccProject,
        isH5PContentEnabled: self.isH5PContentEnabled,
        enableTeacherAssistant: self.enableTeacherAssistant,
        isSbrEnabled: self.isSbrEnabled,
        isOthersEnabled: self.isOthersEnabled
      };
    }
  }))
  .actions(self => ({
    setPreviousViewMode(value) {
      self.previousViewMode = value;
    },
    updateSearchTerm(term) {
      self.searchTerm = term;
    },
    updateLOB(term) {
      self.selectedLoB = term;
    },
    updateCypressplus(value) {
      self.cypressplus = value;
    },
    updateIsTccProject(value) {
      self.isTccProject = value;
    },
    updateIsH5PContentEnabled(value) {
      self.isH5PContentEnabled = value;
    },
    updateTAEnabled(value) {
      self.enableTeacherAssistant = value;
    },
    updateSbrEnabled(value) {
      self.isSbrEnabled = value;
    },
    updateOthers(value) {
      self.isOthersEnabled = value;
    },
    setSortCriteria(value) {
      const criteria = value || SORT_DEFAULT_CRITERIA;
      self.sortCriteria = criteria;
    },
    setProjectPublishStatus(value) {
      const filter = value || STATUS_FILTER_DEFAULT;
      self.projectPublishStatus = filter;
    },
    setProjectPublishPlatform(value) {
      const platform = value || STATUS_FILTER_DEFAULT;
      self.projectPublishPlatform = platform;
    },
    toggleViewMode(value) {
      if (value) {
        self.mode = value;
      }
    },
    setShowNewReleaseContent(value) {
      self.showNewReleaseContent = value;
    },
    updateCookieForNewReleaseContent(userId) {
      const filterObj = {
        showNewReleaseContent: self.showNewReleaseContent
      };
      saveCookie(`${RELEASE_CONTENT_FOR}${userId}`, filterObj, true);
    },
    isHiddenProjectsShowing() {
      return self.sortCriteria === HIDDEN;
    },
    setProductCategory(value) {
      self.productCategory = value;
    }
  }));
