/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SyncIcon from '@mui/icons-material/Sync';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../../components/common/Dialog';

import './MessageDialog.style.scss';

const MessageDialog = props => {
  const {
    store: {
      MessageDialog: {
        closeMessageDialog,
        title,
        message,
        open,
        type,
        showButton,
        buttonText
      }
    }
  } = props;

  const closeDialog = () => {
    closeMessageDialog();
  };

  const getTitle = () => {
    switch (title?.toLowerCase()) {
      case 'warning':
        return (
          <Box display="flex" gap={1}>
            <WarningAmberIcon sx={{ color: '#8b5000', marginTop: '3px' }} />
            {title}
          </Box>
        );
      case 'error':
        return (
          <Box display="flex" gap={1}>
            <ErrorOutlineIcon sx={{ marginTop: '3px' }} />
            {title}
          </Box>
        );
      case 'processing':
        return (
          <Box display="flex" gap={1}>
            <SyncIcon sx={{ color: '#4F616A', marginTop: '3px' }} />
            {title}
          </Box>
        );
      default:
        return title;
    }
  };

  return (
    <CustomDialog
      open={open}
      className={`message-dialog message-dialog--${type}`}
    >
      <DialogHeader onClose={closeDialog} title={getTitle()} />
      <DialogContent>
        <div className="message-dialog__content">
          <p>{message}</p>
        </div>
      </DialogContent>
      {showButton && (
        <DialogActions>
          <Button
            className="message-dialog__btn"
            onClick={closeDialog}
            aria-label="message-dialog-ok-btn"
            data-testid="ok_button"
          >
            {buttonText}
          </Button>
        </DialogActions>
      )}
    </CustomDialog>
  );
};

MessageDialog.propTypes = {
  store: PropTypes.shape({
    MessageDialog: PropTypes.shape({
      open: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      closeMessageDialog: PropTypes.func.isRequired,
      type: PropTypes.string.isRequired,
      showButton: PropTypes.bool.isRequired,
      buttonText: PropTypes.string.isRequired
    })
  })
};

export default inject('store')(observer(MessageDialog));
