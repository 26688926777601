/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  PROJECT_TITLE: 'Unified Dashboard',
  PROJECT_SUBTITLE_LOCKED: '(Locked)',
  BROWSER_TITLE: 'Unified Dashboard',
  LOGOUT_TEXT: 'Sign Out',
  AZURE_COOKIE_NAME: 'myCloudProxySession',
  HEADER_SESSION_TOKEN: 'PearsonExtSTGSSOSession',
  SELECT_DISCIPLINE_ID: 'Select Discipline ID',
  PRINT_ON_DEMAND: 'PDF Export',
  CANCEL: 'CANCEL',
  CONFIRM: 'CONFIRM',
  PROCEED: 'PROCEED',
  CONVERT_TO_PDF: 'CONVERT TO PDF',
  CONVERT_TO_EPUB: 'CONVERT TO EPUB',
  SELECT_THEME: 'Select Theme',
  NEXT: 'Next',
  PROCESSING: 'Processing',
  EPUB_EXPORT: 'ePub export',
  BETA: 'BETA',
  STATISTICS_BETA: 'Statistics BETA',
  STATISTICS_TITLE: 'Statistics',
  CONVERT_PDF_MESSAGE:
    'Your Request has been received and is in process. You will receive the file in your email.',
  OK: 'OK',
  CREATE: 'CREATE',
  COPY_TO_CLIPBOARD: 'Copy to Clipboard',
  COPIED_TO_CLIPBOARD: 'Copied to Clipboard',
  NEXT_VERSION: 'Next Version',
  PREVIOUS_VERSION: 'Previous Version',
  OF: 'of',
  NONE_SELECTED: 'None Selected',
  SEARCH: 'SEARCH',

  VERSIONS: 'Versions',
  VERSIONS_ACTION: 'Action',
  VERSIONS_DISTRIBUTION: 'Delivery Platform',
  VERSION_CREATED_ON: 'Created on',
  VERSION_SUBMITTED_ON: 'Published On',
  VERSION_SUBMITTED_BY: 'Published By',
  VERSION_STATUS: 'Status',
  VIEW_DETAILS: 'View Details',
  SHOW_MORE: 'show more',
  SHOW_LESS: 'show less',
  VIEW_DETAILS_LINK: 'View details',
  AVAILABLE_ACTIONS: 'Available Actions',
  TITLE: 'Title',
  DETAILS: 'Details',
  ACTIONS: 'Actions',
  VERSION_NO_HEADER: '#',
  EDIT_WITH_CYPRESS: 'Cypress Authoring',
  EDIT_WITH_ELM: 'Edit with Elm',
  PROJECT_PREVIEW: 'Preview',
  POD: 'PDF Export',
  IMPORTLINK_TOOLTIP: 'Import',
  EXPORTLINK_TOOLTIP: 'Export',
  PREFLIGHT_CHECK: 'Preflight Toolkit',
  PEER_REVIEW_ANNOTATIONS: 'Peer Review',
  TEAM_MEMBERS: 'Team Members',
  PUBLISH: 'Publish',
  ACTIVITY_STREAM: 'Activity Stream',
  SUGGESTED: 'Suggested',
  VIEW_ALL_ACTIVITIES: 'View All Activity',
  REQUIRED_PARAMETER_MISSING_MSG: 'Required parameter is missing',
  NO_APPROVED_VERSION_MESSAGE: 'No versions available',
  WIP: 'wip',
  APPROVED: 'approved',
  SORT_OR_DRAG_LABEL: 'Click to sort or Drag to reorder',
  SORT_LABEL: 'Sort',
  DRAG_LABEL: 'Drag to reorder',
  SORT_TOOLTIP: 'Click to sort',
  SORT_DEFAULT_CRITERIA: 'default',
  SORT_COOKIE_NAME: 'sort',
  NO_RESULT_FOUND_MSG: 'No results found, try again',
  NO_RESULT_FOUND_MSG_FOR_SEARCH:
    'No matching projects found for the search criteria',
  SUCCESS: 'Success',
  ALERT_TYPE_ERROR: 'error',
  ALERT_TYPE_SUCCESS: 'success',
  ALERT_TYPE_WARNING: 'warning',
  ALERT_TYPE_INFO: 'info',
  CONFIGURE_REVEL_AND_DISTRIBUTE: 'Configure revel and distribute',
  SEARCH_PLACEHOLDER: 'Search Projects',
  RESET_SEARCH: 'Reset Search',
  PAYLOAD_MISSING_MESSAGE: 'Required Paylaod is Missing',
  PREVIEW_URL_ERROR_MSG: 'Error in Generating previewUrl',
  INVALID_URN_MESSAGE: 'The urn is not valid',
  STATUS_FILTER_DEFAULT: 'default',
  STATUS_FILTER_NONE: 'None',
  BACK_TO_PROJECT_DETAILS: 'BACK TO PROJECT DETAILS',
  BACK: 'Back',
  APPLY: 'APPLY',
  // settings Constants
  PREFERENCES: 'Preferences',
  USER_PREFERENCE: 'User Preference',
  PREFERENCES_TOOLTIP: 'Set User Preferences',
  EDIT_YOUR_PREFRENCE_TEXT: 'Edit your preference',
  SAVE: 'SAVE',
  LINE_OF_BUSINESS: 'LineOfBusiness',
  WILLOW_FRAMEWORKS: 'Willow Framework(s)',
  WILLOW_FRAMEWORK_DIALOG_HEADER: 'Willow Frameworks',
  EDIT_WILLOW_FRAMEWORK_OPTION_TOOLTIP: 'Edit Willow Framework(s)',
  DISABLE_EDIT_WILLOW_FRAMEWORK_OPTION_TOOLTIP:
    'Willow Framework cannot be edited when the project is locked.',
  NO_LOB_PLACEHOLDER: 'None added',
  NO_PROJECT_METADATA_INFO:
    'In order to see Project Metadata, a Line of Business must be selected.',
  DEFAULT_SELECTED_LOB: 'None',
  LF_CONFIRMATION_TEXT:
    'Once an External Framework is linked with the project, it cannot be changed. Do you want to proceed?',
  LF_CONFIRMATION_TITLE: 'Willow Framework Confirmation',
  // Http Status codes Constants
  ERROR_CODE_5XX_MESSAGE: 'Something went wrong, try again later',
  ERROR_CODE_401_MESSAGE: 'Your session has expired, please login again',
  ERROR_CODE_403_MESSAGE: 'You are not authorized to perform this action',
  ERROR_CODE_404_MESSAGE: 'Requested resource not found',
  DEBOUNCE_TIMEOUT: 1200,

  // Project Settings constants
  PROJECT_SETTINGS: 'Project Settings',
  EDIT_PROJECT: 'Edit Project',
  AUTHOR: 'Author',
  ALFRESCO_SITE: 'Alfresco Site',
  ALFRESCO_COVER_ART: 'Alfresco Cover Art',
  ASSESSMENT_TYPE: 'Assessment Type',
  ASSESSMENT_VALUE: 'PUF/CITE/TDX',
  CHANGE_TO_LEARNOSITY: 'Change to Learnosity',
  LEARNOSITY: 'Learnosity',
  DEFAULT_LEARNOSITY_TEXT: 'PUF/CITE/TDX',
  PRODUCT_ID: 'Product ID',
  PRODUCT_ID_SETTINGS: 'Product ID',
  QUAD_FOLDER: 'Quad Folder',
  NONE_ADDED: 'None added',
  PLP: 'PLP',
  VALID_PRODUCT_ID: 'Valid Product ID',
  INVALID_PRODUCT_ID: 'Invalid Product ID',
  DUPLICATE_PRODUCT_ID: 'Duplicate Product ID',
  DUPLICATE_PRODUCT_ID_ERROR_LABEL_1: 'This Product ID is already in use by',
  DUPLICATE_PRODUCT_ID_ERROR_LABEL_2:
    '. Please select a different Product ID via Grid Tools.',
  PRODUCT_ID_LABEL: 'Product ID',
  PRODUCT_INFO: 'Product Info',
  LOB_NOT_SELECTED:
    'Note: If inputting PDH product id, please attach line of business as ukschools first',
  ALFRESCO: 'Alfresco',
  EDIT_TITLE_AUTHOR: 'Edit Title and Author',
  MAX_CHAR_LENGTH_MESSAGE: length => `Max ${length} characters`,
  PROJECT_TITLE_MAX_LENGTH: 150,
  PROJECT_AUTHOR_NAME_MAX_LENGTH: 25,
  PROJECT_TITLE_EXISTS_ERROR:
    'This title name already exists, please enter a new title. It cannot be same even for titles in the hidden view.',
  DISABLE_EDIT_TITLE_AUTHOR_TOOLTIP:
    'Title / Author cannot be edited when the project is locked.',
  DISABLE_EDIT_TITLE_AUTHOR_TOOLTIP_NO_PERMISSION:
    'You do not have permission to edit Title / Author field.',
  EDIT_TITLE_AUTHOR_TOOLTIP: 'Edit Title And Author',
  DEFAULT_COVER_ART_COLOR: '#EA7600',
  GLOSSARY_SORTING: 'Glossary Sorting',
  SORT_OPTION: 'Sort Option',
  EDIT_GLOSSARY_SORT_OPTION_TOOLTIP: 'Edit glossary sort option',
  DISABLE_EDIT_GLOSSARY_SORT_OPTION_TOOLTIP:
    'Glossary Sorting cannot be edited when the project is locked.',
  AUTO_NUMBERING: 'Auto Number ToC Containers',
  AUTO_NUMBERING_TOGGLE_ON: 'On',
  AUTO_NUMBERING_TOGGLE_OFF: 'Off',
  AUTO_NUMBERING_TOGGLE_ENABLED_TOOLTIP: 'Enable Auto Number ToC Containers',
  AUTO_NUMBERING_WARNING_DIALOG_TITLE: 'Warning',
  AUTO_NUMBERING_TOGGLE_DISABLE_TOOLTIP:
    'You cannot perform this action. The option is disabled',
  AUTO_NUMBERING_TOGGLE_LOCKED_TOOLTIP:
    'Auto Numbering status cannot be changed when the project is locked.',
  AUTO_NUMBERING_RESTRICTION_FOR_TCC_TOOLTIP:
    'Auto Numbering is disabled for TCC projects.',
  NO_PERMISSION_TO_TOGGLE_AUTO_NUMBERING:
    'You do not have permission to toggle Auto Number ToC Containers.',
  AUTO_NUMBERING_RESTRICTION_FOR_CYPRESS_PLUS:
    'Auto-Numbering cannot be enabled for projects with Cypress+ enabled.',
  AUTO_NUMBERING_RESTRICTION_FOR_CONTENT_OWNER:
    'Auto-Numbering cannot be enabled for projects with Content Owner/Subscriber option enabled.',
  AUTO_NUMBERING_RESTRICTION_TITLE: 'Cannot enable Auto-Numbering',
  ALFRESCO_EPUB_PATH: 'Alfresco ePub path',
  AUTO_NUM_STATUS_INPROGRESS: 'IN_PROGRESS',
  AUTO_NUM_STATUS_SUCCESS: 'SUCCESS',
  AUTO_NUM_STATUS_NOT_FOUND: 'NOT FOUND',
  AUTO_NUM_ENABLE_ERROR_DIALOG_TITLE: 'Enable Auto numbering failed',
  AUTO_NUM_ENABLE_ERROR_DIALOG_CONTENT: dUrn =>
    `Process to enable auto numbering for the title [${dUrn}] has failed or rolled back.`,

  // Revel audio flag overide
  AUDIO_FLAG_ALL_LABEL: 'All',
  AUDIO_FLAG_FIRST_LABEL: 'First',
  AUDIO_FLAG_NONE_LABEL: 'None',
  REVEL_AUDIO_FLAG_OVERRIDE: 'Revel Audio Flag Override',
  AUDIO_FLAG_LOCKED_TOOLTIP:
    'Revel audio flag cannot be changed when the project is locked.',
  AUDIO_FLAG_RADIO_BUTTON_TOOLTIP: 'Change Revel audio flag',

  // Project Notes
  PROJECT_NOTES_LABEL: 'Project Notes',
  EDIT_PROJECT_NOTES_TOOLTIP: 'Edit Project Notes',
  DISABLE_EDIT_PROJECT_NOTES_TOOLTIP:
    'Project notes cannot be edited when the project is locked.',

  // Cypress Plus Toggle
  CYPRESS_PLUS_TOGGLE_LABEL: 'Cypress+',
  CYPRESS_PLUS_TOGGLE_ENABLED: 'Enabled',
  CYPRESS_PLUS_TOGGLE_DISABLED: 'Disabled',
  CYPRESS_PLUS_TOGGLE_ENABLED_TOOLTIP: 'Enable Cypress+',
  CYPRESS_PLUS_DIALOG_TITLE: 'Enabling Cypress+',
  CYPRESS_PLUS_TOGGLE_DISABLED_TOOLTIP:
    'You cannot perform this action. The option is disabled',
  CYPRESS_PLUS_TOGGLE_LOCKED_TOOLTIP:
    'Cypress+ status cannot be changed when the project is locked.',
  CYPRESS_PLUS_WARNING_REGARDING_RESTRICTION:
    'Note: If you continue, you cannot add this project as an Owner/Subscriber or enable Auto-numbering in the future.',
  CYPRESS_PLUS_RESTRICTION_AUTONUMBERING:
    'Cypress+ cannot be enabled for projects with Auto-Numbering enabled.',
  CYPRESS_PLUS_RESTRICTION_TITLE: 'Cannot enable Cypress+',
  CYPRESS_PLUS_RESTRICTION_CONTENT_OWNER:
    'Cypress+ cannot be enabled for projects with the Content Owner/Subscriber option enabled.',
  CYPRESS_PLUS_LABEL: 'Cypress+ Enabled',
  AUTO_NUMBERING_CYPRESS_PLUS_MESSAGE:
    'Auto Numbering and Cypress+ status cannot be changed once project is created.<br/>Only one option can be enabled as Cypress+ does not support auto-numbering.',

  // H5P content toggle

  H5P_CONTENT_TOGGLE_LABEL: 'H5P Content',
  H5P_CONTENT_TOGGLE_ENABLED: 'Enabled',
  H5P_CONTENT_TOGGLE_DISABLED: 'Disabled',
  H5P_CONTENT_TOGGLE_ENABLED_TOOLTIP: 'Enable H5P Content',
  H5P_CONTENT_TOGGLE_DISABLED_TOOLTIP:
    'You cannot perform this action. The option is disabled',
  H5P_CONTENT_TOGGLE_LOCKED_TOOLTIP:
    'H5P Content status cannot be changed when the project is locked.',
  SEGMENT_REQUIRED_FOR_H5P: 'Please select the Segment to enable H5P',
  SEGMENT_NOT_AVAILABE: 'Segment not available',

  // Teacher Assistant and SBR toggle
  TEACHER_ASSISTANT_TOGGLE_LABEL: 'Teacher Assistant',
  TEACHER_ASSISTANT_TOGGLE_LOCKED_TOOLTIP:
    'Teacher Assistant status cannot be changed when the project is locked.',
  TEACHER_ASSISTANT_TOGGLE_ENABLED_TOOLTIP: 'Enable Teacher Assistant',
  SBR_TOGGLE_LABEL: 'Standards Based Reporting',
  SBR_TOGGLE_ENABLED_TOOLTIP: 'Enable Standards Based Reporting',
  SBR_TOGGLE_LOCKED_TOOLTIP:
    'Standards Based Reporting status cannot be changed when the project is locked.',
  TOGGLE_ENABLED: 'Enabled',
  TOGGLE_DISABLED: 'Disabled',
  TEACHER_ASSISTANT_TOGGLE_DISABLE_TOOLTIP: 'Disable Teacher Assistant',
  SBR_TOGGLE_DISABLE_TOOLTIP: 'Disable Standards Based Reporting',

  // Pearson Plus  toggle
  PEARSON_PLUS_TOGGLE_LABEL: 'Pearson+ Study tool',
  PEARSON_PLUS_TOGGLE_LOCKED_TOOLTIP:
    'Pearson+ status cannot be changed when the project is locked.',
  PEARSON_PLUS_TOGGLE_ENABLED_TOOLTIP: 'Enable Pearson+',
  PEARSON_PLUS_TOGGLE_DISABLE_TOOLTIP: 'Disable Pearson+',

  // Publish and Configure Warning
  PUBLISH_CONFIG_WARNING_DIALOG_TITLE: 'Warning',
  PUBLISH_CONFIG_WARNING_MESSAGE:
    'This project is currently marked as Cancelled. Please un-cancel this project in Advanced Settings before attempting to distribute it.',

  // Cancel project
  CANCEL_THIS_PROJECT: 'Mark project as Cancelled',
  UNCANCEL_THIS_PROJECT: 'Un-cancel this project',
  CANCEL_THIS_PROJECT_LOCKED_TOOLTIP:
    'Cancel status cannot be changed when the project is locked',
  CANCEL_LIVE_PROJECT_CONFIRMATION:
    'This project is currently published Live. Are you sure you want to mark it Cancelled?',

  // Track Changes
  TRACK_CHANGES: 'Track changes',
  TRACK_CHANGES_TOOLTIP: 'Enable / Disable Track Changes',
  TRACK_CHANGES_TOOLTIP_DISABLE:
    'Track Changes cannot be enabled when the project is locked.',
  TRACK_CHANGES_TOOLTIP_DISABLE_NO_PERMISSION:
    'You do not have permission to toggle track changes',
  TRACK_CHANGES_ON: 'On',
  TRACK_CHANGES_OFF: 'Off',
  TRACK_CHANGES_HEADER: 'There are pending track changes for this version.',
  TRACK_CHANGES_WARNING_BODY:
    'Please contact an admin with permission to accept all pending track changes before attempting to turn track changes off',
  TRACK_CHANGES_CONFIRM_BODY:
    'Do you want to accept all track changes and turn track changes off?',

  TRACK_CHANGES_BUTTON_CONFIRM: 'Confirm',
  TRACK_CHANGES_BUTTON_CLOSE: 'Close',

  DISABLE_LINK_ALFRECO_TOOLTIP:
    'Alfresco Site cannot be edited when the project is locked.',
  DISABLE_LINK_ALFRECO_TOOLTIP_NO_PERMISSION:
    'You do not have permission to Link Alfresco Site',
  LINK_ALFRECO_TOOLTIP: 'Link Alfresco Site',
  EDIT_ALFRESCO_EPUB_PATH: 'Edit Alfresco ePub path',
  ALFRESCO_EPUB_PATH_LOCKED_TOOLTIP:
    'The path is locked and if a change is required it can be made via the backend',
  ALFRESCO_EPUB_PATH_CONFIRMATION_TEXT:
    'The path will be locked and if a change is required it can be made via the backend',
  ALFRESCO_EPUB_PATH_WARNING_TEXT:
    'Please select folder to update Alfresco ePub path',
  DISABLE_EDIT_ALFRESCO_EPUB_PATH_NO_PERMISSION:
    'You do not have permission to edit Alfresco ePub path',
  DISABLE_EDIT_ALFRESCO_EPUB_PATH:
    'Alfresco ePub path cannot be edited when the project is locked.',
  DISABLE_LEARNOSITY_TOOLTIP_NO_PERMISSION:
    'You do not have permission to add item bank',
  DISABLE_LEARNOSITY_TOOLTIP_PROJECT_LOCKED:
    'Learnosity item cannot be edited when the project is locked.',
  LEARNOSITY_WARNING_TEXT:
    'Please confirm that this project does not have any existing assessment items before proceeding. A project cannot have both Non-Learnosity and Learnosity authored assessment.',
  LEARNOSITY_TEXT:
    'Please double-check your selection prior to confirmation. Item banks cannot be changed once associated to a project except by back-end engineers.',
  PROJECT_TYPE_TEXT:
    'Please double-check your selection prior to confirmation. Project Type cannot be changed once associated to a project except by back-end engineers.',
  CONTINUE_BUTTON: 'Continue',
  LOB_TEXT:
    'Please double-check your selection prior to confirmation. Line of business item cannot be changed once associated to a project except by back-end engineers.',
  PRE_FLIGHT_ROUTEURL: (id, entityUrn) =>
    `preflight/projectId/${id}/entityId/${entityUrn}`,
  ALFRESCO_VIEWER_ROUTEURL: (id, entityUrn) =>
    `alfrescoViewer/projectId/${id}/entityId/${entityUrn}`,
  IMPORT_ROUTEURL: (id, entityUrn) =>
    `import/projectId/${id}/entityId/${entityUrn}`,
  PROJECT_DETAILS_ROUTEURL: (id, entityUrn) =>
    `project/version/${id}/entity/${entityUrn}`,
  DOWNLOAD_ASSET_ROUTEURL: (location, id) =>
    `download/${id}/asset?location=${location}`,
  ALFRESCO_LINK_REQUIRED_FOR_IMPORT_MESSAGE:
    'An Alfresco site link is required prior to importing a file.',
  ROLE_EDITOR: 'edit',
  ROLE_DEFAULT: 'default_user',
  ROLE_REVIEWER: 'comment_only',
  NO_ACCESS: 'no_access',
  ROLE_MANAGER: 'manager',
  ROLE_ADMIN: 'admin',
  ROLE_STANDARDROLES: 'Standard Roles',
  ROLE_CUSTOMROLES: 'Custom Roles',
  ROLE_EDIT: 'Edit Role',
  ROLE_EDIT_HEADING: 'You are editing the role of the following user(s)?',
  ROLE_FAVORITED: 'Favorited Roles',
  EDIT_QUAD_FOLDER: 'Edit Quad Folder',
  EDIT_QUAD_FOLDER_TOOLTIP: 'Edit Quad Folder',
  VIEW_QUAD_FOLDER_TOOLTIP: 'View Quad Folder',
  DISABLE_EDIT_EMPTY_QUAD_FOLDER_TOOLTIP:
    'You do not have permission to edit the Quad Folder field.',
  QUAD_FOLDER_DIALOG_NO_PERMISSION_MESSAGE:
    'You do not have permission to edit this field.',
  DISABLE_EDIT_QUAD_FOLDER_TOOLTIP:
    'Quad Folder cannot be edited when the project is locked.',
  EDIT_PRODUCT_ID_TOOLTIP: 'Edit Product ID',
  DISABLE_EDIT_PRODUCT_ID_TOOLTIP:
    'Product ID cannot be edited when the project is locked.',
  DISABLE_EDIT_PRODUCT_ID_TOOLTIP_NO_PERMISSION:
    'You do not have permission to edit the Product ID field.',
  CLOSE_DRAWER_TOOLTIP: 'Close Drawer',

  // Ebook
  EBOOK_ENABLE_TOOLTIP: 'Enable eBook',
  EBOOK_DISABLED_LOCKED_TOOLTIP:
    'eBook status cannot be changed when the project is locked',
  EBOOK_DISABLED_TOOLTIP: 'Once eBook is enabled it cannot be changed',

  // EPUB
  EPUB_CONFIRMATION_MESSAGE:
    'Are you sure want to generate the ePub output for this project?',
  EPUB_SUCCESS_MESSAGE: 'Your request has been received and is in process.',
  EPUB_FAILURE_MESSAGE: 'Request cannot be processed, try again later',
  EPUB_SUCCESS: 'success',
  EPUB_TOOLTIP: 'ePub Export BETA',
  ADD_PRODUCT_ID: 'Add Product ID',
  DELETE_PRODUCT_ID: 'Delete Product ID',
  DELETE_PRODUCT_ID_INFO:
    'ProductID cannot be deleted for a project that has already been published live.',
  ISBN_WARNING: 'Warning:',
  ISBN_VALIDATION_MESSAGE: 'Your request could not be processed',
  SKIP: 'Skip',
  ISBN_REQUIRED: 'Please enter Parent ISBN',
  INVALID_ISBN: 'Invalid Parent ISBN',
  ISBN_NOT_FOUND: 'Parent ISBN does not exist',
  NO_WORK_IN_PROGRESS_MESSAGE: 'This title is Locked.',
  CREATE_NEW_VERSION_MESSAGE_APPROVED: `If you would like to make a change, please create a new version \n from the table below.`,
  IN_PROGRESS_MESSAGE: 'This version is currently being delivered.',
  CREATE_NEW_VERSION_MESSAGE_IN_PROGRESS:
    'These options are not available at this time.',
  TITLE_LOCKED_HEADER: 'This title is Locked',
  TITLE_LOCKED_DUE_TO_AUTONUM_INFO:
    'The project is undergoing a transition to complete auto-numbering. Project will become available once the process it complete.',

  VERSION_STATUS_FAILURE: 'FAILURE',
  VERSION_STATUS_IN_PROGRESS: 'IN-PROGRESS',
  VERSION_STATUS_WIP_IN_PROGRESS: 'WIP-IN-PROGRESS',
  VERSION_STATUS_DELIVERED: 'DELIVERED',
  VERSION_STATUS_DISPLAY_FAILED: 'FAILED',
  VERSION_NO_STATUS: 'NO_STATUS',
  NO_DISTRIBUTABLE_MESSAGE:
    'You have no distributions. Click the ‘Send’ icon to distribute.',
  VERSION_COMPLETED_DISPLAY_STATUS: 'COMPLETED',
  VERSION_APPROVED: 'APPROVED',
  VERSION_LOCKED: 'LOCKED',
  VERSION_WIP: 'WIP',
  VERSION_WIP_STATUS: 'WORK IN PROGRESS',
  VERSION_WIP_FAILED: 'WIP-FAILED',
  VERSION_WIP_DELIVERED: 'WIP-DELIVERED',
  VERSION_IN_REVIEW: 'IN-REVIEW',
  VERSION_WIP_IN_REVIEW: 'WIP-IN-REVIEW',
  VERSION_WIP_PUBLISHED_LIVE: 'WIP-PUBLISHED-LIVE',
  VERSION_IN_REVIEW_STATUS: 'IN REVIEW',
  VERSION_PUBLISHED_LIVE_DISPLAY_STATUS: 'PUBLISHED LIVE',
  VERSION_PUBLISHED_LIVE: 'PUBLISHED-LIVE',
  VERSION_POST_C6_DELIVERED: 'POST-C6-DELIVERED',
  VERSION_POST_C6_IN_PROGRESS: 'POST-C6-IN-PROGRESS',
  VERSION_POST_C6_FAILED: 'POST-C6-FAILED',
  // these will display on UI
  VERSION_POST_C6_FAILED_DISPLAY_STATUS: 'PC6-FAILED',
  VERSION_POST_C6_DELIVERED_DISPLAY_STATUS: 'PC6-DELIVERED',
  VERSION_POST_C6_IN_PROGRESS_DISPLAY_STATUS: 'PC6-IN-PROGRESS',
  VERSION_WIP_POST_C6_DELIVERED: 'WIP-PC6-DELIVERED',
  VERSION_WIP_POST_C6_IN_PROGRESS: 'WIP-PC6-IN-PROGRESS',
  VERSION_WIP_POST_C6_FAILED: 'WIP-PC6-FAILED',
  ICON_NEW_VERSION: 'New Version',
  VERSION_NOT_AVAILABLE: 'Not Available',
  NO_VALID_DATA_POD: 'PDF Export not available',
  NO_VALID_DATA_PREVIEW: 'Preview not available',
  NO_VALID_DATA_EPUB: 'ePub Export not available',
  ICON_DUPLICATE: 'Duplicate',
  ICON_GOLIVE: 'Go Live',
  ICON_COMMENTS: 'Comments',
  FAVORITE_BUTTON_TOOLTIP: 'Favorites',
  PRODUCT_PLATFORM_TOOLTIP: 'Filter by Product Model',

  REVEL_BAU: 'Revel BAU',
  ETEXT2_BAU: 'EText2 BAU',
  REVEL_GLP: 'Revel GLP',
  REVEL_TOOLTIP: 'Distribute to Revel',
  ETEXT2_GLP: 'EText2 GLP',
  TYPE_REVEL: 'REVEL',
  TYPE_ETEXT2: 'ETEXT2',
  DESTINATION_CITE: 'CITE',
  DESTINATION_GLP: 'GLP',
  DESTINATION_PLP: 'PLP',
  DESTINATIN_ILP: 'ILP',
  DESTINATION_PCX: 'PCX',
  DESTINATION_PRIZM: 'PRIZM',

  ETEXT2_BAU_LABEL: 'eText BAU',
  ETEXT2_BAU_TOOLTIP: 'Distribute to eText',
  GPS_LABEL: 'GPS',
  GPS_TOOLTIP: 'Distribute to GPS',
  CITE: 'CITE',
  BAU: 'BAU',
  VIRTUALSCHOOLS: 'Virtual Schools',
  PRIZM: 'PRIZM',
  LINE_OF_BUSINESS_REQUIRED_TITLE: 'Line of business is required!',
  LOB_VALIDATION_MESSAGE: platformName =>
    `Line of business is required before publishing to ${platformName}`,
  PUBLISH_STATUS_ERROR_TITLE: 'Project Locked',
  PUBLISH_STATUS_ERROR: 'Project is already approved',

  PUBLISH_TCM_TITLE: 'Cannot promote while there are pending track changes.',
  PUBLISH_TCM_CONFIRM_BODY:
    'Do you want to accept all track changes and turn track changes off?',
  PUBLISH_TCM_WARNING_BODY:
    'Please contact a manager with permission to accept all pending track changes before attempting to promote again.',
  PUBLISH_PRODUCT_ID_ERROR:
    'This project cannot be promoted, it is missing Product ID.',

  PUBLISH_PRODUCT_ID_ERROR_TITLE: 'Product ID required to promote!',
  PUBLISH_PRODUCT_ID_ERROR_MESSAGE:
    'A valid Product ID must be assigned before the version can be promoted.  Please assign the Product ID in the Project Settings draw.',
  PUBLISH_PRODUCT_ID_ERROR_MESSAGE_GO_LIVE:
    'A valid Product ID is required before going Go Live',
  PUBLISH_SUCCESS_MESSAGE: 'Your request has been received and is in process',
  PUBLISH_CONFIRMATION_MESSAGE:
    '<p>Approving a version will lock it and no further edits can be made to it.</p><p>All content QA including text, toc item titles, widgets and assessments should be final at this point</p><p>Approving it means you are ready to distribute it to products</p>',
  PUBLISH_CANCEL_LABEL: 'NO, CANCEL',
  PUBLISH_APPROVE_LABEL: 'YES, APPROVE',
  PUBLISH_APPROVE: 'Approve',
  PUBLISH_SUCCESS_DIALOG_TITLE: 'Request Received',
  OK_BUTTON_LABEL: 'Ok button',
  CONFIRM_BUTTON_LABEL: 'Confirm button',
  CANCEL_BUTTON_LABEL: 'Cancel button',

  CREATE_NEW_VERSION_FAILED: 'Create new version failed',
  FILTER_FOR: 'filter_for_',
  RELEASE_CONTENT_FOR: 'release_content_for_',
  TUTORIAL_FOR: 'tutorial_for_',
  PRODUCT_ID_ERROR_TYPE: 'productIdError',
  PROJECT_META_DATA: 'projectMetadata',
  PROJECT_COVER_IMAGE: 'projectCoverImage',
  LIST_VIEW_MODE: 'list',
  LIST_VIEW_MODE_TOOLTIP: 'List View',
  GRID_VIEW_MODE: 'grid',
  GRID_VIEW_MODE_TOOLTIP: 'Grid View',
  TABLE_VIEW_MODE: 'table',
  TABLE_VIEW_MODE_TOOLTIP: 'Table View',
  REQUEST_PROJECT_ACCESS_MODE: 'RequestProjectAccessView',
  FAVORITE_FILTER: 'favorites',
  LAST_OPEN_BY_ME_FILTER: 'lastOpenedByMe',
  // Forbidden Page:
  FORBIDDEN_MESSAGE:
    'You are not authorized to access this application. Please reach out to your manager to get access.',
  FORBIDDEN_MESSAGE1: applicationName =>
    `The ${applicationName} is restricted to Pearson employees and contractors with valid credentials.`,
  FORBIDDEN_MESSAGE2: 'You are currently missing the following permission(s):',

  FORBIDDEN_PEDEV_ACCOUNT_HELP:
    'https://teams.microsoft.com/l/channel/19%3a78e978be21474f618581f4a3bba0ab7b%40thread.tacv2/PEDEV%2520Account%2520Setup?groupId=ded19458-78e1-4ec2-9e69-d38ae05608c9&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b',
  FORBIDDEN_IES_AES_PROD:
    'https://teams.microsoft.com/l/channel/19%3aa2a907dbc9d948e881cab94de59c433a%40thread.tacv2/IESAES-Prod?groupId=ded19458-78e1-4ec2-9e69-d38ae05608c9&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b',
  FORBIDDEN_IES_AES_NON_PROD:
    'https://teams.microsoft.com/l/channel/19%3adf3790458d3f4d8ab3537bdb84a7537d%40thread.tacv2/IESAES%2520User%2520Setup-NonProd?groupId=ded19458-78e1-4ec2-9e69-d38ae05608c9&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b',

  AD_GROUP_ADMIN: 'AD group, PACE-StructuredContentAdmin',
  AD_GROUP_EDITOR: 'PACE-StructuredContentEditor',
  FORBIDDEN_MESSAGE3:
    'Please contact your direct line manager to request access.',
  FORBIDDEN_MESSAGE4:
    'for instructions on how to request access to AD groups for ',
  FORBIDDEN_MESSAGE5: 'to open the MyHelp page',
  CLICK_HERE: 'Click here',
  DIALOG_TITLE_WARNING: 'Warning',
  DIALOG_TITLE_ERROR: 'Error',
  DIALOG_TITLE_UNAUTHORIZED: 'Unauthorized',
  DIALOG_TITLE_FORBIDDEN: 'Forbidden',
  DIALOG_TITLE_NOT_FOUND: 'Not Found',
  DIALOG_TITLE_MESSAGE: 'Message',
  // Version Distribution constants
  PRODUCT_MODEL_REVEL: 'REVEL',
  PRODUCT_MODEL_ETEXT2: 'ETEXT2',

  DESTINATION_GPS: 'GPS',
  DESTINATION_BAU: 'BAU',

  ETEXT: 'eText',
  REVEL: 'Revel',

  DIALOG_TYPE_DEFAULT: 'default',
  DIALOG_TYPE_ERROR: 'error',
  DIALOG_TYPE_WARNING: 'warning',

  USER_INFO: 'userInfo',
  COOKIE_MAX_AGE: 86400,

  VERSION_DETAIL_DIALOG_TITLE: 'Title',
  VERSION_DETAIL_DIALOG_AUTHOR: 'Author',
  VERSION_DETAIL_DIALOG_STATUS: 'C6 status',
  VERSION_DETAIL_DIALOG_DISTRIBUTION: 'Delivery Platform',
  VERSION_DETAIL_DIALOG_URN: 'URN',
  VERSION_DETAIL_DIALOG_ALFRESCO: 'Alfresco site',
  VERSION_DETAIL_DIALOG_ASSESSMENT: 'Assessment',
  VERSION_DETAIL_DIALOG_QUAD_FOLDER: 'Quad Folder',
  VERSION_DETAIL_DIALOG_DATE_CREATED: 'Date created',
  VERSION_DETAIL_DIALOG_ORIGIN: 'Origin',
  VERSION_DETAIL_DIALOG_SOURCE_TITLE: 'Source Title',
  VERSION_DETAIL_DIALOG_SOURCE_DURN: 'Source dURN',
  VERSION_DETAIL_DIALOG_SOURCE_ENV: 'Source Env',
  VERSION_DETAIL_DIALOG_DATE_SUBMITTED: 'Date submitted',
  VERSION_DETAIL_DIALOG_SUBMITTED_BY: 'Submitted by',
  VERSION_DETAIL_DIALOG_PRODUCT_ID: 'Product ID',
  VERSION_DETAIL_DIALOG_NOT_AVAILABLE: 'Not available',
  ACCEPET_ALL_TCM_FAIL_MESSAGE:
    "Accept All Track changes API completed successfully but did not receive expected 'status' in response",

  ICON_CONFIG_AND_PUBLISH: 'Publish and Configure',
  DISABLE_TOOLTIP_CONFIG_AND_PUBLISH_ICON:
    'Distribution of title is not applicable for Content Owners',
  INACTIVE_ICON_CONFIG_AND_PUBLISH:
    'Option locked. Use latest project version to access.',
  UNABLE_TO_DISTRIBUTE: 'Unable to Distribute',
  IMPORT_INPROGRESS_DIALOG_CONTENT:
    'Content import is still processing. This project cannot be distributed until content import has completed. Please try again later.',
  IMPORTED_FILE_PROCESSING_STATUS: 'processing',

  // Build info related constants
  NOT_AVAILABLE: 'Not Available',
  BUILD_INFO: 'Build Info',
  BUILD_INFORMATION: 'Build Information',
  DASHBOARD_API_V2_SERVER_INFO: 'DASHBOARD API V2 SERVER INFO',
  UDB_DASHBOARD_API_SERVER_INFO: 'UDB DASHBOARD API SERVER INFO',
  DASHBOARD_UI_INFO: 'DASHBOARD UI INFO',
  PROD_URL: 'paf.pearson.com',
  PROD_PACE_URL: 'pace.pearson.com',
  PERF_URL: 'paf-perf.pearson.com',
  PERF_PACE_URL: 'pace-prf.pearson.com',
  PROD_IES_URL: 'iam.pearson.com',
  NON_PROD_IES_URL: 'iam-stage.pearson.com',
  PERF_IES_URL: 'iam-ppe.pearson.com',
  // UDB-9675- PAICE IES/AES Migration (Ph2) - MFA and Google social login integration
  REDIRECT_TO_IES_LOGIN:
    '/auth/XUI/?realm=pearson&authIndexType=service&authIndexValue=AuthN&client_id=PAICE-V2',
  IES_LOGOUT_URL: '/auth/XUI/?realm=/pearson#logout',

  // User Management
  USER_MANAGEMENT_HEADER_TITLE: 'Project Team Management - Cypress',
  USER_MANAGEMENT_TEAM_MEMBERS: 'Team Members',
  USER_MANAGEMENT_ADD_USER: '+Add User',
  USER_MANAGEMENT_CUSTOM_ROLE: 'Custom Role',
  USER_MANAGEMENT_ADD_CUSTOM_ROLE: 'Add Custom Role',
  USER_MANAGEMENT_EDIT_CUSTOM_ROLE: 'Edit Custom Role',
  USER_MANAGEMENT_CUSTOM_ROLE_NAME_VALIDATION_MESSAGE: 'Max 20 characters',
  USER_MANAGEMENT_CUSTOM_ROLE_NAME_VALIDATION_MESSAGE_FOR_VALID_CHARACTERS:
    'Custom Role name cannot begin with a special character',
  USER_MANAGEMENT_CUSTOM_ROLE_EXISTS_VALIDATION_MESSAGE:
    'This name is already being used by another custom role.',
  USER_MANAGEMENT_CUSTOM_ROLE_EXISTS_VALIDATION_MESSAGE_API:
    'Role id already exists in the database',
  USER_MANAGEMENT_CUSTOM_ROLE_NAME: 'Name',
  USER_MANAGEMENT_ADD_USERS: 'Add user(s) to project(s)',
  USER_MANAGEMENT_ADD_USER_DIALOG_TITLE: 'Request New User Account',
  USER_MANAGEMENT_ADD_USER_DIALOG_MESSAGE:
    'If you need to request access for a new user, follow the instructions contained in the PDF link below',
  USER_MANAGEMENT_ADD_USER_REQUEST_ACCESS_LINK:
    'REQUEST ACCESS FOR NEW USER INSTRUCTIONS',

  USER_MANAGEMENT_SEARCH_MEMBERS: 'Search Members',
  USER_MANAGEMENT_USERID: 'User ID',
  USER_MANAGEMENT_EMAIL: 'Email',
  USER_MANAGEMENT_ROLE: 'Role',
  USER_MANAGEMENT_UPDATE_USER_ROLE: 'Edit User(s) Role',
  USER_MANAGEMENT_REMOVE_BUTTON_LABEL: 'Remove',
  USER_MANAGEMENT_SELECT_ROLE: 'Select User Role',
  USER_MANAGEMENT_USER_LABEL: 'Team Member',
  USER_MANAGEMENT_USERS_LABEL: (noOfUser, noOfadmin) =>
    `<b>Team Members</b> - ${noOfUser} | <b>Admins</b> - ${noOfadmin}`,
  USER_MANAGEMENT_SELECTED_USERS_LABEL: 'Team Members selected',
  USER_MANAGEMENT_SELECTED_USER_LABEL: 'Team Member selected',
  USER_MANAGEMENT_ADMIN_LABEL: 'Admin',
  USER_MANAGEMENT_ADDED_LABEL: 'Added',
  USER_MANAGEMENT_NA_LABEL: 'N/A',
  USER_MANAGEMENT_REMOVE_USER: 'Remove User',
  USER_MANAGEMENT_REMOVE_USER_DIALOG_TITLE: 'Remove User',
  USER_MANAGEMENT_REMOVE_USERS_DIALOG_TITLE: 'Remove Users',
  USER_MANAGEMENT_REMOVE_USER_DIALOG_MESSAGE:
    'Do you want to remove the following user from the project?',
  USER_MANAGEMENT_REMOVE_USERS_DIALOG_MESSAGE:
    'Do you want to remove the following users from the project?',
  USER_MANAGEMENT_REMOVE_USER_DIALOG_OK: 'REMOVE',
  USER_MANAGEMENT_REMOVE_USER_DIALOG_CANCEL: 'Cancel',
  USER_MANAGEMENT_ADD_NEW_USER_DIALOG_ADD_BUTTON: 'Add',
  USER_MANAGEMENT_ADD_NEW_USER_TITLE: 'Add New User(s)',
  USER_MANAGEMENT_ADD_NEW_USER_DIALOG_SEARCH_PLACEHOLDER: 'Search by UserID*',
  USER_MANAGEMENT_REMOVE_BUTTON_TOOLTIP: 'Remove User',
  USER_MANAGEMENT_DISABLE_REMOVE_BUTTON_NO_PERMISSION:
    'You do not have permission to Remove User',
  USER_MANAGEMENT_NO_PERMISSION_MESSAGE:
    'There are no permissions specific for this role',

  EDIT_INSTRUCTOR_RESOURCE_TOOLTIP: 'Edit Instructor Resource URL',
  DISABLE_EDIT_INSTRUCTOR_RESOURCE_TOOLTIP:
    'Instructor Resource URL cannot be edited when the project is locked.',
  INSTRUCTOR_RESOURCE_SETTINGS: 'Instructor Resource',
  NO_PERMISSION_INSTRUCTOR_RESOURCE:
    'You do not have permission to edit the Instructor Resource field.',
  INSTRUCTOR_DIALOG_LABEL: 'Add instructor resource url',
  INSTRUCTOR_HELPER_TEXT:
    'Enter a Valid URL. https://www.example.com/instructorurl.html',
  INSTRUCTOR_RESOURCE_REMOVE_DIALOG_LABEL: 'Remove Instructor Resource',
  INSTRUCTOR_RESOURCE_REMOVE_DIALOG_MESSAGE:
    'Do you want to remove Instructor Resource URL?',
  REMOVE_INSTRUCTOR_RESOURCE_TOOLTIP: 'Remove Instructor Resource URL',
  NO_RESULT_FOUND: 'There were no results that matched your search criteria.',
  MANDATORY_SITE_FOR_EPUB_TCC_MESSAGE:
    'ePub and TCC projects require an Alfresco site to be linked',
  USER_MANAGEMENT_DEFAULT_SORT_DIRECTION: 'desc',
  USER_MANAGEMENT_COLUMN_USER: 'userId',
  USER_MANAGEMENT_COLUMN_EMAIL: 'email',
  USER_MANAGEMENT_COLUMN_ROLE: 'roleLabel',
  USER_MANAGEMENT_COLUMN_ADDED: 'lastModifiedDate',
  USER_MANAGEMENT_COLUMN_REMOVE_USER: 'removeUser',
  USER_MANAGEMENT_NO_MEMBERS_MSG:
    'No Team Members found that match the search criteria.',
  USER_MANAGEMENT_SEARH_RESULTS_MSG:
    '*Search results limited to Team Members not already in the project.',
  SELECT_ROLE: 'Select Role',
  ROLE_PERMISSIONS: 'Role Permissions',
  USER_MANAGEMENT_INVALID_USERID_MSG: '*Not a valid Pearson userID',
  USER_MANAGEMENT_DUPLICATE_USERID_MSG: 'has already been added.',
  USER_MANAGEMENT_ALREADY_MEMBER_TOOLTIP: 'User is already in the project',
  USER_MANAGEMENT_INVALID_USERID_TOOLTIP: 'Invalid userID',
  USER_MANAGEMENT_CREATED_BY: 'Created By',
  USER_MANAGEMENT_ADMIN_TOOLTIP: 'User is an admin',
  USER_MANAGEMENT_ALREADY_MEMBER_ALERT_TOOLTIP:
    'User is already associated with one or more selected projects and user role might get updated',
  USER_MANAGEMENT_UNAUTHORIZED_USER_MSG:
    'User account information has a mismatch',
  USER_ACCOUNTS: 'User Accounts',
  LOGGED_IN_USER: 'Logged in user',

  // Create new project
  CREATE_NEW_PROJECT_TOOLTIP: 'Create new project',
  CREATE_NEW_PROJECT: 'New Project',
  CREATE_PROJECT: 'Create New Project',
  CREATE_PROJECT_TITLE: 'Project Title',
  ALFRESCO_DETAILS: 'Alfresco details',
  ALFRESCO_LOCATION_HEADING: 'Alfresco Storage Location',
  SELECT_ALFRESCO_DETAILS: 'Select an Alfresco Site for your Project',
  CREATE_MULTIPLE_PROJECTS: 'Create multiple projects',
  ANNOTATIONS_NOT_FOUND: 'Peer review annotations not found',
  CREATE_PROJECT_AUTHOR: 'Author',
  AUTHOR_PLACEHOLDER: 'First and Last Name',
  CREATE_PROJECT_CANCEL: 'Cancel',
  CREATE_PROJECT_SAVE: 'Save',
  IMPORT_TYPE_STANDARD: 'PACE Standard',
  IMPORT_TYPE_TCC: 'TCC',
  IMPORT_TYPE_EPUB: 'ePub',
  IMPORT_TYPE_EPUB_LABEL: 'Simplified ePub',
  IMPORT_TYPE_TCC_LABEL: 'TCC (Thin Common Cartridge)',
  IMPORT_TYPE_EBOOK: 'eBook',
  IMPORT_TCC: 'Import TCC',
  USER_OUTLOOK_365_URL: email =>
    `https://outlook.office365.com/owa/service.svc/s/GetPersonaPhoto?email=${email}&amp;UA=0&size=HR48x48&sc=1597181182935`,
  USER_OUTLOOK_URL: email =>
    `https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=${email}&amp;UA=0&size=HR48x48&sc=1597181182935`,
  ADVANCED_SETTINGS: 'Advanced Settings',
  REMOVE_PROJECT_FROM_UDB: 'Remove project from UDB',
  DISABLE_REMOVE_PROJECT_FROM_UDB:
    'Project cannot be removed when the project is locked',
  PROJECT_TYPE_HEADING: 'Project Type',
  PROJECT_TYPE_STANDARD: 'Standard',
  EDIT_PROJECT_TYPE_TOOLTIP: 'Edit Project type',
  RESET_PROJECT_TYPE: 'Reset Project type',
  DISABLE_PROJECT_TYPE_TOOLTIP_LOCKED:
    'Project type cannot be edited when the project is locked.',
  DISABLE_RESET_PROJECT_TYPE_TOOLTIP_LOCKED:
    'You cannot reset Project type when the project is locked.',
  PROJECT_TYPE_RESET_MESSAGE:
    'You are about to reset Project Type. Are you sure you want to proceed?',
  LINE_OF_BUSINESS_HEADING: 'Line Of Business',
  LOB_EDIT_TOOLTIP: 'Edit Line of business',
  DISABLE_LOB_EDIT_TOOLTIP_LOCKED:
    'Line of business cannot be edited when the project is locked.',
  DISABLE_LOB_RESET_TOOLTIP_LOCKED:
    'Line of business cannot be reset when the project is locked.',
  RESET_LOB: 'Reset Line Of Business',
  RESET_LOB_WARNING_MESSAGE_LINE:
    'You are about to reset the Line Of Business. Please check for any association of below LOB dependent Metadata before proceeding.',
  LINE_OF_BUSINESS_UPDATED: 'Line Of Business Updated',

  GO_BACK_PROJECT_SETTINGS_TOOL_TIP: 'Go back project settings',
  NO_PEARSON_IDS_MATCHED: 'No Pearson IDs matched.  Please try again.',
  REMOVE_PROJECT_CONFIRMATION: name =>
    `Please confirm that you want to remove "${name}" from all dashboard user's view.`,
  HIDE: 'HIDE',
  UNHIDE: 'UNHIDE',
  UNHIDE_POPUP_TITLE: 'Unhide project on UDB',
  UNHIDE_MESSAGE_FOR_LOCKED_TITLE: name =>
    `"${name}" is <b>locked</b> cannot unhide`,
  UNHIDE_PROJECT_CONFIRMATION: name =>
    `Please confirm that you want to <b>unhide</b> "${name}" on the Unified Dashboard.`,
  UNHIDE_PROJECT_CONFIRMATION_NOTE:
    'Note: This project will be visible to other users in UDB',
  NEW_RELEASE_DIALOG_TITLE: "What's New",
  NEW_RELEASE_DIALOG_FOOTER_MSG: 'Do not show me this message again',
  ARROW_DIRECTION_LEFT: 'left',
  ARROW_DIRECTION_RIGHT: 'right',
  NEW_APP_MANAGEMENT: 'Message Console',
  NEW_RELEASE_MENU_TITLE: `What's New?`,
  NOTIFICATIONS_AND_TUTORIALS: 'Notifications and Tutorials',
  SHOW_HIDE_TABLE_COLUMNS: 'Show/Hide Table Columns',
  HIDDEN: 'hidden',
  SHOW: 'show',
  SHOW_LABEL: 'Show',
  ALPHABETICALLY: 'alphabetically',
  ALFRESCO_SITE_ERROR: 'You must choose storage location in Alfresco',
  LEARNOSITY_ERROR: 'You must select lernosity',

  // Release Management
  RELEASE_NAME: 'Release Name',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  PREVIEW: 'Preview',
  VISIBLE_TO_USERS: 'Visible to users',
  CHOOSE_FILES: 'Choose Files',
  TIME: 'Time',
  TODAY: 'Today',

  // InDesign-Ready XML
  INDESIGN_CONFIRMATION_MESSAGE:
    'Are you sure want to generate the InDesign output for this project?',
  INDESIGN_SUCCESS_MESSAGE: 'Your request has been received and is in process.',
  INDESIGN_FAILURE_MESSAGE: 'Request cannot be processed, try again later',
  INDESIGN_SUCCESS: 'success',
  INDESIGN_TOOLTIP: 'InDesign-Ready XML',
  INDESIGN_EXPORT_TITLE: 'InDesign-Ready XML',

  START_TOUR: 'Start Tour',
  STOP_TOUR: 'Stop Tour',
  // Request Project Access
  REQUEST_ACCESS: 'Request Access',
  REQUEST_ACCESS_NOTIFICATION_TEXT1:
    'To request access to this project, click on the copy icon',
  REQUEST_ACCESS_NOTIFICATION_TEXT2:
    'in the top right corner to copy the project details. Then paste them into an email to your line manager.',
  PROJECT_DETAILS: 'details',
  BACK_TO_LISTS: 'Back to lists',
  WATCH_VIDEO: 'Watch video',
  VIDEO_TAG_NOT_SUPPORTED: 'Your browser does not support the video tag.',
  NOTIFICATIONS: 'NOTIFICATIONS',
  TUTORIALS: 'TUTORIALS',
  REMOVE_VIDEO: 'Remove video',
  REMOVE_THUMBNAIL: 'Remove thumbnail icon',
  PREVIEW_VIDEO: 'Preview video',
  PREVIEW_LINK: 'Preview link',
  DELETE_CONFIRMATION:
    ' Are you sure want to delete this Notification/Tutorial?',
  DELETE_SLIDE_VIDEO_CONFIRMATION:
    ' Are you sure want to delete attached video for ',
  ACTION_SETTINGS: 'Action settings',
  CHOOSE_THUMBNAIL: 'Choose thumbnail',
  CHOOSE_VIDEO: 'Choose video',
  SAVE_ORDER: 'Save Order',
  CLEAR: 'Clear',
  UPDATE: 'Update',
  DESCRIPTION_REQUIRED: 'Description is required',
  LABEL_REQUIRED: 'Label is required',
  WEB_URL_REQUIRED: 'Web URL is required',
  ALIGNMENT: 'Alignment',
  TYPE: 'Type',
  FEATURE_TYPE: 'Feature Type',
  TARGET: 'Target',
  TARGET_OPEN: 'Open In',
  INVALID_URL: 'Invalid Url',
  AUTO_HIDE_DRAWER: 'Hide drawer',
  NONE: 'None',

  // Import ePub
  IMPORT_EPUB_HELPER_TEXT:
    'Split ePub files will be placed in a folder next to this selected ePub',

  // import file mimetypes
  EPUB_IMPORT_FILE_MIMETYPE: 'application/epub+zip',
  TCC_IMPORT_FILE_MIMETYPE: 'application/zip',
  STANDARD_IMPORT_FILE_MIMETYPE: 'application/pdf',

  // Import PDF
  IMPORT_FILE_HELPER_TEXT:
    'Create a project from the following supported file formats',
  IMPORT_PDF_HELPER_TEXT:
    'Split PDF files will be placed in a folder next to this selected PDF',
  IMPORT_PDF_LABEL: 'Choose Storage Location',
  IMPORT_PDF_BUTTON: 'IMPORT',
  IMPORT_PDF_SELECT_BUTTON: 'SELECT',
  IMPORT_EPUB: 'Import Simplified ePub',
  // Advance setting subject
  SUBJECT_PRIMARY_TEXT: 'Subject',
  KEYSTAGE_PRIMARY_TEXT: 'KeyStage',
  SKILLS_PRIMARY_TEXT: 'Skills',
  PROPERTY_PRIMARY_TEXT: 'Property',
  TAXONOMY_EDIT_TOOLTIP: taxonomyType => `Edit ${taxonomyType}`,
  DISABLE_TAXONOMY_EDIT_TOOLTIP_FOR_LOCKED_PROJECT: taxonomyType =>
    `${taxonomyType} cannot be edited when the project is locked.`,
  DISABLE_TAXONOMY_EDIT_TOOLTIP: taxonomyType =>
    `${taxonomyType} cannot be edited.`,
  DISABLE_TAXONOMY_EDIT_UKLOB_NOT_SELECTED_TOOLTIP: taxonomyType =>
    `Line of Business must be UK Schools for assigning a ${taxonomyType}`,
  DISABLE_TAXONOMY_EDIT_NOLOB_TOOLTIP: taxonomyType =>
    `Line of Business must be set before assigning a ${taxonomyType}`,

  // Lob Workflow
  LOB_MANAGER: 'LOB Manager',
  WORK_FLOW_ROLES: 'Workflow Roles',
  SELECT_LOB: 'Select LOB',
  ADD_WORK_FLOW_TEXT: '+ Add Workflow',
  ADD: 'Add',
  CYPRESS_RULES: 'Cypress Rules',
  THEMES: 'Themes',
  DISABLE_EDIT_THEME_TOOLTIP:
    'Theme cannot be edited when the project is locked.',
  EDIT_THEME_TOOLTIP: 'Edit Theme',
  THEME_PRIMARY_TEXT: 'Theme',
  NO_ASSOCIATED_THEME: 'No associated theme',
  NO_WORKFLOW_ROLE_TEXT: 'No workflow role available for selected LOB',
  NO_CYPRESS_RULE_TEXT: 'No cypress rule available for selected LOB',

  // LOB Manager: Content Metadata
  TAXONOMY_TYPE_HEADER: 'Taxonomy Type',
  NO_TAXONOMY_TYPES: 'No taxonomy types available for selected LOB',
  NO_TAXONOMIES: 'No taxonomies available for selected taxonomy type',
  ADD_TAXONOMY_WARNING: 'Once added, this cannot be deleted.',

  // LOB Manager: Project metadata tab
  PROJECT_METADATA: 'Project Metadata',
  PROJECT_METALINK: 'Meta Links',
  PROJECT_CONTENT_METADATA: 'Content Metadata',
  LOB_META_TYPE_METADATA: 'metadata',
  LOB_META_TYPE_METALINK: 'metalink',

  // Preferences
  BUSINESS_UNIT_AFFILIATIONS: 'Business Unit Affiliations',

  // Feature Manager
  FEATURE_MANAGER: 'Feature Manager',

  // User Manager
  USER_MANAGER: 'User Manager',
  AI_USER_ACCESS_MANAGER: 'AI User Access',
  PVS_ADD_BULK_USERS: 'PVS Add Bulk Users',
  SPECIAL_PERMISSION_USERS: 'Permissions',
  NO_SPECIAL_PERMISSIONS: 'No Special Permission is available',
  SPECIAL_PERMISSION_SEARCH_USER_LABEL: 'Search by UserID or Email',
  SPECIAL_PERMISSION_NO_USER_FOUND:
    'No users found in our DB that match the search criteria.',
  SPECIAL_PERMISSION_EDIT_DISABLE_FOR_ADMIN:
    'This permission cannot be changed for Admins',

  // Imports
  IMPORT_SNO: '#',
  FILE_NAME: 'File name',
  IMPORT_FILE_TYPE: 'Type',
  IMPORTED_BY: 'Imported by user',
  IMPORTED_ON: 'Import started',
  IMPORTED_ENDED: 'Import ended',
  STATUS: 'Status',
  ERROR_REPORTING: 'Error details',
  NO_IMPORT_MSG: 'No activities to display.',

  // exports
  EXPORT_SNO: '#',
  EXPORT_TRANSFORMATION_TYPE: 'Type',
  EXPORTED_BY: 'Exported by user',
  EXPORTED_ON: 'Export started',
  EXPORT_ENDED: 'Export ended',
  EXPORT_STATUS: 'Status',
  EXPORT_ERROR_REPORTING: 'Error details',
  NO_EXPORT_MSG: 'No activities to display.',
  ERROR_COPY_ICON: 'Copy error details',

  NO_RECORDS_FOUND: 'No records found',
  TOUR_SLIDES: 'Tutorial',

  // Project Activities:
  ACTIVITIES_SNO: '#',
  NO_ACTIVITIES_MESSAGE: 'No activities to display',
  ACTIVITY: 'Activity',
  USER_ID: 'User ID',
  CREATION_DATE: 'Creation Date',
  OLD_VALUE: 'Old Value',
  NEW_VALUE: 'New Value',
  SUMMARY: 'Summary',
  ACTIVITY_STATUS: 'Status',

  // Export status
  PROCESSING_STATUS: 'processing',
  EXPORT_REQUEST_MESSAGE:
    'Your request was not submitted because there is already a request in progress.',
  PROJECT_ACTIVITY: 'Project Activity',

  // Statistics Data
  LEGEND_LABEL: text => `${text} Cases`,

  PDF: 'pdf',
  EPUB: 'epub',
  INDESIGN: 'indesign',
  EPUB_PROJECT_MESSAGE: 'This feature is not available for ePub projects',
  TCC_PROJECT_MESSAGE: 'This feature is not available for TCC projects',
  CONTENT_OWNER_SET_TO: 'Content Owner set to',
  OWNER: 'OWNER',
  SUBSCRIBER: 'SUBSCRIBER',
  OWNER_LABEL: 'Owner',
  SUBSCRIBER_LABEL: 'Subscriber',
  NO_SUBSCRIPTION_LABEL: 'No Subscription',
  SUBSCRIPTION: 'Content Owner',
  SUBSCRIPTION_TYPE: 'Content Owner Type',
  SUBSCRIPTION_NO_SUBSCRIPTION_TOOLTIP: 'Change Content Owner',
  SUBSCRIPTION_DISABLE_TOOLTIP:
    'Content Owner cannot be changed when the project is locked.',
  SUBSCRIPTION_RADIO_BUTTON_TOOLTIP: 'Change content owner type',
  SUBSCRIPTION_RESET: 'Reset Content Owner type',
  SUBSCRIPTION_RESET_BUTTON_TOOLTIP_LOCKED:
    'You cannot reset Content Owner when the project is locked.',
  SUBSCRIPTION_RESET_MESSAGE:
    'You are about to reset Content Owner type. Are you sure you want to proceed?',
  UPDATE_CONTENT_OWNER_CONFIRMATION:
    'Once the content owner is assigned it cannot be changed.',
  UPDATE_CONTENT_OWNER_CONFIRMATION_NOTE1:
    'Note: If you continue, you cannot enable Auto-numbering or Cypress+ in the future.',
  CONFIRM_BUTTON: 'CONFIRM',
  SUBSCRIPTION_RESTRICTION_AUTO_NUMBERING:
    'The Content Owner/Subscriber option cannot be enabled for projects with Auto-numbering enabled.',
  SUBSCRIPTION_RESTRICTION_CYPRESS_PLUS:
    'The Content Owner/Subscriber option cannot be enabled for projects with Cypress+ enabled.',
  SUBSCRIPTION_RESTRICTION_TITLE: 'Cannot change Content Owner',

  // statistics dialog
  STATISTICS_PROJECT_TITLE: 'Project title:',
  XRAY_PROJECT_SUMMARY: 'XRAY PROJECT SUMMARY',
  DATE_INFO: 'This information below is correct as of',
  OPEN_COMMENTS: 'OPEN COMMENTS',
  CLOSED_COMMENTS: 'CLOSED COMMENTS',
  COMMENTS: 'COMMENTS',
  CLOSE: 'CLOSE',
  PRINTER_FRIENDLY_VERSION: 'Printer Friendly Version',
  PROVISION_MUST_FIX: 'PROVISION MUST FIX',
  MUST_FIX: 'MUST FIX',
  SHOULD_FIX: 'SHOULD FIX',
  NEEDS_ANALYSIS: 'NEEDS ANALYSIS',
  BETA_UAT: 'BETA-UAT',
  TOTAL_REPORTED_ERRORS: 'Total reported errors:',
  PREFLIGHT_SUMMARY: 'PREFLIGHT SUMMARY',
  LAST_REPORT: 'Last report run:',
  NO_REPORT_RUN: 'No report run',

  USERS: 'Users',
  FILE_URLS_MGMT: 'File Urls',
  UPDATE_FILE_URL: 'Update file Url',
  SELECT_KEY: 'Select key',
  SELECT_KEY_MESSAGE: 'Please select key',
  SELECT_FILE_MESSAGE: 'Please select file',
  CHOOSE_FILE: 'Choose File',

  // comments dialog
  NO_DATA_AVAILABLE: 'No data available for selected filter(s)',
  NO_COMMENTS_AVAILABLE: 'No comments available',
  OPEN: 'Open',
  RESOLVED: 'Resolved',
  REPLY_COMMENTS: 'Reply comments',
  ERROR_FETCHING_COMMENTS: 'Error in fetching comments',

  // Preferneces
  LAYOUTS: 'Layouts',
  FILTERS: 'Filters',
  PRODUCT_MODEL: 'Product Model',
  DATE_DISPLAY_FORMAT: 'lll',

  // Project notes
  PROJECT_NOTES_TITLE: 'Project Notes',
  PRINT_LABEL: 'Print',
  PROJECT_NOTES_LAST_UPDATE: 'This information was last updated:',

  // Product Filter
  DEV_MODE: 'Dev Mode',

  CONFIRMATION_DIALOG_TITLE: 'Are you sure?',

  // Preflight check dialog
  PREFLIGHT_CHECK_DIALOG_TITLE: 'Preflight Check',

  // One time enable action
  ONE_TIME_ENABLE_WARNING:
    'This action cannot be undone. Are you sure you want to continue?',

  // distribution
  DISTRIBUTION_PLATFORM_LABEL: 'Platform Name',
  DISTRIBUTION_PRODUCT_LABEL: 'Select Product Model',
  DISTRIBUTION_GPS_PRODUCT_LABEL: 'Product Model',
  DISTRIBUTION_REGION_LABEL: 'Region',
  DISTRIBUTION_TABLES_HEADER_TITLE: 'PUBLISHING LOG',
  DISTRIBUTION_NO_TRANSACTIONS_MESSAGE:
    'No transaction logged for this distribution.',
  AUTO_REFRESH_STOP_MESSAGE_FOR_DISTRIBUTION:
    'Auto Refresh Warning: No Progress in the past 2 minutes, stopping Auto Refresh. Check back later',

  // uPDF export
  UPDF_EXPORT_TOOLTIP: 'uPDF Export',
  UPDF_EXPORT: 'uPDF Export',
  DISTRIBUTION_DISABLE_AT_PLATFORM_MSG: platform =>
    `Distribution to ${platform} is disabled.`,
  USER_HAS_NO_PERMISSION_TO_DISTRIBUTE_MSG: platform =>
    `You do not have permission to distribute to ${platform}.`,
  DISABLE_DISTRIBUTION_ACROSS_ALL_PLATFORM:
    'Distribution to all platforms is disabled.',
  ALFRESCO_PATH_ERROR:
    'Please add the Alfresco site in Project Settings to proceed.',
  DELIVERY_TRANSACTION_TYPE: 'DELIVERY',
  C6_DELIVERY_TRANSACTION_TYPE: 'C6 Delivery',
  ERROR_DIALOG_HEADER: 'Unable to Config and Publish!',
  ERROR_DIALOG_SUBHEADER: 'Please update the open item(s) below',
  MAX_ALLOWED_USER_LENGTH: length => `Max allowed users are ${length}`,
  SUCCESS_MESSAGE_FOR_USER_ADDED_TO_AI_ACCESS:
    'Users have been successfully added to use PACE AI.',
  INCORRECT_ENTITY_URN: 'Incorrect entityURN',

  BLUEPRINT: 'Blueprint',
  EDIT_BLUEPRINT: 'Edit Blueprint',
  // Blueprint IPM
  BLUEPRINT_IPM_PROJECT_ID: 'Blueprint IPM Project Number',
  BLUEPRINT_PROJECT_DETAILS: 'Blueprint Project Details',
  IPM_PROJECT_NUMBER: 'IPM Project Number',
  INVALID_IPM_PROJECT_NUMBER: 'Invalid IPM Project Number',
  BLUEPRINT_PRODUCT_ID: 'Blueprint Product ID',
  DELINK: 'Delink',
  BLUEPRINT_EDIT_LOCKED:
    'Blueprint cannot be edited when the project is locked',
  BLUEPRINT_NOT_FOUND:
    'IPM Project not found. Please check if its available in Blueprint.',
  BACK_DROP_CLICK: 'backdropClick',
  BLUEPRINT_PRODUCT_ID_DELINK: 'Blueprint product id delink',
  BLUEPRINT_PRODUCT_ID_LINK: 'Blueprint product id link',
  DASHBOARD_HOMEPAGE: 'dashboard_homepage',
  PROJECT_DETAIL_PAGE: 'project_detail_page',
  PROJECT_PREVIEW_MESSAGE: (
    name,
    previewGeneratedDate
  ) => `A Publisher Preview of <b>${name}</b> was generated on <br />
  ${previewGeneratedDate}.<br />
  <br /> Generating new preview may take some time. Would you like to
  save time by opening existing Publisher Preview?`,
  H5P_ENABLED_PROJECT: 'H5P enabled',
  MARKED_FAVORITE: 'Checked favorite',
  MARKED_UNFAVORITE: 'Unchecked favorite',

  DISABLE_EDIT_AUDIENCE_TYPE_TOOLTIP:
    'Audience Type cannot be edited when the project is locked.',
  EDIT_AUDIENCE_TYPE_TOOLTIP: 'Edit Audience Type',
  AUDIENCE_TYPE_PRIMARY_TEXT: 'Audience Type',

  // IMPORT FILE
  IMPORT_FILE: 'Import File',
  IMPORT_SUCCESSFUL_REQUEST_MSG:
    'Your request has been received and is in process.',
  IMPORT_INVALID_FILE_TYPE: fileType =>
    `Please select a valid ${fileType} file`,
  IMPORT_CANCEL: 'Cancel',
  IMPORT_CSV_CONTENT:
    'Spreadsheet import allows to update the project based on the mapping provided in the file.',
  IMPORT_PDF_CONTENT:
    'This will import the PDF file and process it to create the narrative content which can be further edited in Cypress+ or Cypress.',
  ACCESS_DENIED: 'Access Denied',
  ERROR_MESSAGE_NO_PROJECT_ACCESS:
    'You do not have access to English LOB projects.',
  ENGLISH_LOB_PERMISSION_TIMEOUT: 3000,
  PAFURL: 'paf',
  PACEURL: 'pace',
  STG_URL: 'paf-staging.pearson.com',
  STG_PACE_URL: 'pace-stg.pearson.com',
  DEPRECATED_HEADING: 'Unified Dashboard has a new home',
  DEPRECATED_DESCRIPTION: (pafDomain, paceDomain) =>
    `We have moved from ${pafDomain} to ${paceDomain}. Please update your bookmarks as this link will be inactive soon.`,
  DEPRECATED_CLICK_HERE: 'Click here to continue to the new link.',
  FILTER_PROJECTS_BY: 'Filter Projects by:',

  // PVS Add Bulk Users

  PVS_ADD_BULK_USERS_SUCCESS: 'Users successfully added',

  // AI Onboarding
  PAICE_AI_CAPABILITIES: 'PAICE AI Capabilities',
  AI_ONBOARDING_BODY:
    'To get immediate access, just select your line of business and accept the terms of usage. If you work with multiple LOB, then please select the one where you are most involved.',
  TERMS_OF_USAGE: 'Terms of Usage',
  DOWNLOAD_PDF: 'Download PDF',
  I_AGREE: 'I Agree',
  HED: 'Higher Education',
  AQ: 'Assessment & Qualifications',
  WFS: 'Workforce Skills',
  ELL: 'English Language Learning',
  PVS: 'Pearson Virtual Schools',
  DT: 'Digital & Technology',
  DTC: 'Direct to Consumer',
  EXPORT_PDF_CONTENT:
    ' Select this option if you want to export the project in PDF format. You will also be able to choose a theme on the next screen.',
  EXPORT_EPUB_CONTENT:
    'Select this option if you want to export the project in ePub format. You will optionally be able to enter the ISBN on the next screen.',
  EXPORT_INDESIGN_CONTENT:
    'Select this option if you want to export the project in XML format compatible with InDesign.',
  EXPORT_UPDF_CONTENT:
    'Select this option if you want to export the project in Universal PDF format. You will also be able to choose a theme on the next screen.',
  ALFRESCO_PATH_ERROR_FOR_EXPORT:
    'You must add the Alfresco site in Project Settings to proceed. \n\n Open: Project Settings > Link Alfresco Site ',
  ISBN_HELPER_TEXT:
    'Please enter a valid ISBN if available or leave blank to skip.'
};
